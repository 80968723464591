@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

ul.p-inputtext.p-chips-multiple-container {
  width: 46pc;
  margin-bottom: 0px;
  padding-left: 10px;
}
.p-tabmenu-nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0px !important;
}
a.p-ripple.p-element.p-menuitem-link {
  text-decoration: none !important;
  color: #ae0040 !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #ae0040 !important;
}
p-menubarsub {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.p-dropdown-items {
  padding: 0px !important;
  margin: 0px !important;
}

.p-dropdown.p-component {
  height: 32px;
  .p-dropdown-label.p-inputtext {
    display: flex !important;
    align-items: center !important;
    padding: 0 10px !important;
  }
}

input {
  height: 32px;
}

.p-accordion-header-link {
  color: white !important;
  background: #ae0040 !important;
  text-decoration: none !important;
  height: 40px !important;
  font-size: 14px !important;
}

.p-card.p-component {
  box-shadow: 0 0 3px #000000;
}

.p-card-title {
  color: #ae0040 !important;
  padding: 0px !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #ae0040 !important;
  box-shadow: 0 0 0 0.2rem rgba(174, 0, 64, 0.25) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: rgba(174, 0, 64, 0.25) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-hover {
  background: rgba(174, 0, 64, 0.01) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ae0040 !important;
  background: rgba(174, 0, 64, 0.01) !important;
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #ae0040;
  background: rgba(174, 0, 64, 0.06) !important;
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #ae0040;
  background: rgba(174, 0, 64, 0.03) !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(174, 0, 64, 0.25) !important;
  border-color: #ae0040;
}
.p-chips-token-label {
  font-size: var(--subHeadingFontSize) !important;
}

.p-inputtext:enabled:focus {
  outline: 0 !important;
  outline-offset: 0;
  box-shadow: none;
  border-color: #ae0040 !important;
}

.p-inputtext {
  font-size: 13px !important;
  color: #495057 !important;
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(174, 0, 64, 0.25) !important;
  border-color: #ae0040 !important;
}
.p-calendar:enabled:focus {
  outline: 0 !important;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(174, 0, 64, 0.25) !important;
  border-color: #ae0040 !important;
}
.p-inputtext:enabled:hover {
  border-color: #ae0040 !important;
  box-shadow: 0 0 0 0.2rem rgba(174, 0, 64, 0.25) !important;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 20px !important;
}

.p-tabview
  .p-tabview-nav
  li
  .p-tabview-nav-link:not(.p-disabled):focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 600 !important;
  font-size: 12px !important;
}
.modal-body {
  padding: 0px !important;
}
.p-dialog-header {
  border-bottom: 1px solid #cecece !important;
  padding: 16px !important;
}
.p-dialog-footer {
  border-top: 1px solid #cecece !important;
  padding: 16px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 5px !important;
  margin: 2px 0px 2px 0px !important;
}
