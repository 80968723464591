/* You can add global styles to this file, and also import other style files */

@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./plugin.scss";
:root {
  --headingFontSize: 16px;
  --subHeadingFontSize: 14px;
  --descriptionFontSize: 12px;
}

/* for form css */
.form-control:focus {
  border-color: #ae0040 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(174, 0, 64, 0.25) !important;
}

.form-control {
  font-size: 13px !important;
}
.model-lable {
  font-size: 20px !important;
}
.form-col {
  margin-bottom: 0.5rem !important;

  label {
    margin-bottom: 0.5rem !important;
    font-size: 13px !important;

    span {
      color: red !important;
      font-size: 13px !important;
    }
  }

  .form-field-email {
    display: flex !important;
    justify-content: space-between !important;
  }

  small {
    color: red;
    font-size: 13px !important;
  }
}

.form-field-address {
  h5 {
    font-size: var(--headingFontSize) !important;
    text-align: center !important;
  }
}

textarea {
  resize: none !important;
}

/* form button */
.form-check-input:checked {
  background-color: #ae0040 !important;
  border-color: #ae0040 !important;
}

.form-check-input:focus {
  border-color: #ae0040 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(174, 0, 64, 0.25);
}

.form-check-input {
  margin-right: 10px;
}

.btn-sm-theme {
  background-color: #ae0040 !important;
  color: #fff;
  border: #ae0040 !important;
  height: 34px !important;
  width: 34px !important;
}

.btn-sm-theme {
  i {
    color: #fff;
  }
}

.btn-submit {
  background-color: #ae0040 !important;
  color: #fff !important;
  margin-left: 20px !important;
  height: 32px !important;
  padding: 0px 10px !important;
}

.btn-cancel {
  background: #495057 !important;
  color: #fff !important;
  height: 32px !important;
  padding: 0px 10px !important;
}

/* tag css */
a {
  font-size: var(--headingFontSize) !important;
}

button {
  font-size: var(--subHeadingFontSize) !important;
}

/* form-field custom css */
.custom-form-container {
  padding: 0px 10px !important;
}

.padding-30px {
  padding: 30px !important;
}
.no-padding {
  padding: 0px !important;
}

.width-100-perc {
  width: 100% !important;
}

.design-color {
  color: #ae0040 !important;
  font-weight: bold !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accordion-item,
.accordion-item:focus,
.accordion-item:active,
.accordion-header,
.accordion-header:focus,
.accordion-header:active,
.accordion-button,
.accordion-button:focus,
.accordion-button:active {
  outline: none !important;
  box-shadow: none !important;
  border-radius: 0% !important;
}

.btn-default {
  background-color: #ae0040 !important;
  border: #ae0040 !important;
  font-size: var(--headingFontSize) !important;
  color: #fff !important;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.sticky-menubar {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Adjust as needed based on your layout */
}

.menu-list {
  display: flex !important;
  justify-content: flex-start !important;
}

/* text css */
.text-invalid {
  color: red;
}

.text-white {
  color: #fff !important;
}
.scrool-overflow-auto {
  overflow: auto !important;
  // width: 300px !important;
  height: 200px !important;
}

.dropdown-item:active {
  color: #ae0040 !important;
  text-decoration: none;
  background: rgba(174, 0, 64, 0.01) !important;
}

.custom-card-design {
  height: 300px !important;
  overflow: auto !important;
}
// For Model dialog height fix
.custom-model-design {
  height: 400px !important;
}

.padding-top-bottom-20 {
  padding: 20px 0px 20px 0px !important;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none !important;
}
